
.App {
  text-align: center;
  overflow-y: hidden;
}


.web-devs {
  background-color: #FEFEFF;
}

.overlay {
  background-color: rgba(0,0,0,.6);
  height: 100%;
  /* background-color: #000000cc; */
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FEFEFF;
  opacity: 0;

  animation: fadeInAnimation ease-in 1s;
  animation-delay: 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  
}

.web-devs-people{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 100px;
}

.devs_box{
  height: 100%;
  margin-bottom: 110px;
}

.dev_imgs{
  aspect-ratio: 1/1;
  width: calc(225px + 0.6vw);
  object-fit: contain;
}

.App-header {
  background-color: #29335C;
  height: 540px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 100px;
}

body {
  overflow-x: hidden ;
}

.web-dev-image{
   border: 'solid';
   border-color: 'yellow' ;
   border-width: '5px';
   border-radius: "50%";
}

.mentors {
  background-color: #FEFEFF;
  height: 1200px;
  margin-top: 10%;
}

.mentor-cards1 {
  background-color: #FEFEFF;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-top: 40px;

}
.mentor-cards2 {
  background-color: #FEFEFF;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}
.our-mentors-header {
padding: 20px;
}
.mentors-container{
  background-color: #FEFEFF;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

@media (max-width: 768px) {
  .mentors-container{
    background-color: #FEFEFF;
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  .mentor-cards1 {
    background-color: #FEFEFF;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .mentor-cards2 {
    background-color: #FEFEFF;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .web-devs {
    padding-top: 1100px;
  }
}
