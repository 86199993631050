

.App {
    text-align: center;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    overflow-x: hidden
}

* {
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
}
.philDesc{
margin-left: 10%;
margin-right: 10%;
margin-top: 2%;
}
.main {
    width: 100vw;
    height: calc(100vh - 80px);
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

video::-webkit-media-controls {
    display: none;
}
  

body {
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }

.content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0;

    animation: fadeInAnimation ease-in 1s;
    animation-delay: 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.overlay {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    height: calc(100vh - 80px);
    background-color: rgba(0,0,0,.6);
    /* background-color: #000000cc; */
}

#title {
    color: white;
    font-size: 50px;
    font-weight: 500;
    margin-bottom: 5vh;
    margin-left: 5px;
    margin-right: 5px;
}

#signUp {
    background-color: transparent;
    color: white;
    padding: 15px 30px;
    border: 1px solid white;
    font-size: 24px;
    margin-bottom: 3vh;
    transition-duration: 0.4s;
}

#signUp:hover {
    color: black;
    background-color: white;
    border-radius: 15px;
}

@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

.bigDiv {
    display: flex;
    align-self: center;
    overflow: hidden;
    perspective: 1px;
    border-width: 0px;
    border-color: #fcba03;
    border-style: solid;
    background-color: #FEFEFF;
}


.header {
    font-size: 50px;
    font-family: 'Abel', sans-serif;
    padding-left: 2%;
    padding-right: 2%;
    color: #000;
}

.intro {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #D6EFFF;
    flex-shrink: 0.15;
    padding: 20px;
    width: 50%;
}

.aboutImg {
    object-fit: cover;
    min-width: 0%;
    flex-shrink: 2;
    flex-basis: 3000px;
    border-radius: 40%;
    border-width: 50px;
    margin-left: 2%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.aboutTextDiv {
    justify-content: center;
    text-align: center;
    padding: 20px;
    flex-basis: 1800px;
    flex-grow: 0;
    margin-top: 5%;
    margin-bottom: 5%;
}

.reverse {
    flex-direction: row-reverse;
}

.registerBtn {
    font-size: 20px;
    padding: 20px;
    border-radius: 50px;
    background-color: #0076FF;
    color: #fcba03;
    border-color: #fcba03;
}

.mySlide {
    height: 100%;
}

.gold {
    color: #000;
}

.contact {
    max-width: 95%;
    margin-top: 6%;
    margin-left: 2.5%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.boxesDiv {
    background: linear-gradient(to bottom, #FEFEFF 50%, #D6EFFF);
}

.miniBoxesDiv {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
}
/* //ec255a red
//faedf0 white */

#aboutClasses {
    background-color: #D6EFFF;
    color: black;
    text-align: center;
    width: 100vw;
    margin-left: 0;
    margin-right: 0;
}

#aboutClassesTitle {
    font-size: 43px;
    font-weight: 500;
    padding-top: 50px;
    padding-bottom: 50px;
}

#classBox {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 70px;
    align-items: center;
}

.box {
    background-color: #FEFEFF;
    text-align: left;
    color: black;
    margin-left: 30px;
    margin-right: 30px;
    padding: 10px 20px 10px 20px;
    border-radius: 25px;
    max-width: 400px;
    min-height: 367px;
}

#box2 {
    margin: 5vh 5vw 5vh 5vw;
}

.classNames {
    font-size: 28px;
    font-weight: bold;
    margin: 5px 0 5px 0;
    border-bottom: 3px solid;
}

.classDesc {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 18px;
}

#nextsession {
    background-color: #FEFEFF;
}

#nextTitle {
    font-size: 43px;
    font-weight: bold;
    padding-top: 40px;
    padding-bottom: 40px;
}

#sessionTable {
    text-align: center;
    justify-self: center;
    font-size: calc(20px + 0.208333333vw);
    padding: 10px 10px 30px 10px;
}
.nextSessions{
    padding-bottom: 40px;
}

th {
    font-size: calc(20px + 0.4vw);
    border-bottom: 1px solid;
}

td {
    font-size: calc(12px + 0.6vw);
}

td, th {
    border-left: 1px solid;
    border-right: 1px solid;
    padding: 0.78125vw 2.083333333vw 0.78125vw 2.083333333vw;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

a:visited { 
    text-decoration: none; 
    color: white; 
    text-decoration: none;
}

a{
    text-decoration: none;
    color: white;
}
   