@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Titillium Web', sans-serif;
}

:root{
    --mainColor: #29335C;
    --mainColorLight: #5767aa;
    --secondaryColor: #dbf801;
    --textColor: #eee;
}

header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    background-color: var(--mainColor);
    color: var(--textColor);
}

#logo {
    display: flex;
    flex-flow: row wrap;
    text-align: left;
    align-items: center;
}

#mwmc {
    padding-left: 15px;
}

nav a{
    margin: 0 2rem;
    color: var(--textColor);
    text-decoration: none;
}

nav a:hover{
    color: var(--secondaryColor);
}

header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}

@media only screen and (max-width: 768px){
    header .nav-btn{
        visibility: visible;
        opacity: 1;
        position: 'sticky'; 
        top: 0; 
        z-index: 9999;
    }

    header nav{
        position: fixed;
        top: 0;
        left: 0;
        height: 50rem;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: var(--mainColor);
        transition: 1s;
        transform: translateY(-150vh);
        z-index: 9999;
    }

    header .responsive_nav{
        transform: none;
        position: 'sticky'; 
        top: 0; 
        z-index: 9999;
    }

    nav .nav-close-btn{
        position: absolute;
        top: 2rem;
        right: 2rem;
        position: 'sticky'; 
        top: 0; 
        z-index: 9999;
    }

    nav a{
        font-size: 1.5rem;
        position: 'sticky'; 
        top: 0; 
        z-index: 9999;
    }
}