.slideDiv {
    min-height: 60vh;
    max-height: 50vh;
    overflow: hidden;
}

@media only screen and (max-width: 768px) {
    .slideDiv {
      display:none;
    }
}

img.slide {
    position: relative;
    max-height: 100%;
    min-height: 100%;
    width: 100%;
    object-fit: cover;
    margin-bottom: -0.5%;
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
}

img.up2 {
    transform: translateY(-200%);
}

img.up1 {
    transform: translateY(-100%);
}