.card-container{
    width: 20rem;
    height: 32rem;
    overflow: hidden;
    color: #29335C;
    background-color: #D6EFFF;
    border: solid;
    border-radius: 20px;
    margin-left: 1%;
    margin-right: 1%;
    padding: 10px;
}

.image-container img{
    overflow: hidden;
    height: 200px;
    width: 200px;
    margin-top: 10px;
    border-radius: 200px;
    border: solid;
    border-color: #D6EFFF;
}

.image-container {
    width: 100%;
}

.card-title {
    margin-top: 30px;
    margin-bottom: 30px;
}


.img{
    object-fit: cover;
}

@media (max-width: 768px) {
.card-container{
    width: 20rem;
    height: 20rem;
    overflow: hidden;
    color: #29335C;
    background-color: #D6EFFF;
    border: solid;
    border-radius: 20px;
    margin-left: 10px;
    margin-right: 10px;
    
}

.card-body{
    color: #D6EFFF;
    font-size: 10px;
}
}